<template>
  <div>
    <NCard size="medium" :bordered="false">
      <SearchForm ref="searchFormRef" @list-update="handlePageChange(1)" />
      <div style="margin-bottom: 15px;">
        <NSpace justify="space-between">
          <NSpace>
            <NButton
              v-if="ownUrlPermission('material/distribute')"
              :disabled="selectedIds.length === 0"
              @click="toDistribute('multiple')"
            >批量分发</NButton>
            <NButton
              v-if="ownUrlPermission('material/delete')"
              :disabled="selectedIds.length === 0"
              @click="toDel('multiple')"
            >批量删除</NButton>
          </NSpace>
          <NButton v-if="ownUrlPermission('material/create')" type="primary" @click="handleClickAdd">新增教材</NButton>
        </NSpace>
      </div>
      <NAlert
        v-show="selectedIds.length > 0"
        style="margin-bottom: 10px;"
        type="primary"
      >
        已选择 <span :style="{ color: themeSettings.appThemeColor }">{{ selectedIds.length }}</span> 项数据
      </NAlert>
      <NDataTable
        :columns="columns"
        :data="tableData"
        :row-key="row => row.id"
        remote
        :pagination="pagination"
        @update:page="handlePageChange"
        @update:page-size="handlePageSizeChange"
        v-model:checked-row-keys="selectedIds"
      />
    </NCard>
  </div>

  <Distribute
    ref="distributeRef"
    distribute-title="分发教材"
    fix-content
    content-title="教材"
    :distribute-contents="materialsForDistribute"
    :distribute-fn="distributeMaterials"
    @distribute-success="handlePageChange(1)"
  />
  <CreateModal
    ref="createModalRef"
    @list-update="handlePageChange(1)"
  />
  <PageLoading :loading="loading" />
</template>

<script setup>
  import { h, ref, reactive, onMounted } from 'vue';
  import { NSpace, NButton, NTime, useMessage, useDialog } from 'naive-ui';
  import { useRouter } from 'vue-router';

  import PageLoading from '@/components/PageLoading/index.vue';
  import SearchForm from './components/SearchForm.vue';
  import Distribute from '@/components/Distribute/Distribute.vue';
  import CreateModal from './components/CreateModal.vue';

  import { 
    getMaterialList,
    batDelMaterial,
    distributeMaterials
  } from '@/api/material.js';
  import themeSettings from '@/settings/theme-settings.js'; 
  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  const { SUCCESS } = resStatusEnum;

  const router = useRouter();
  const dialog = useDialog();
  const message = useMessage();
  const loading = ref(false);
  const searchFormRef = ref(null);

  const selectedIds = ref([]);
  const columns = [
    { type: 'selection' },
    { title: '教材 ID', key: 'id' },
    { title: '教材名称', key: 'title' },
    { title: '创建者', key: 'admin_username' },
    {
      title: '更新日期',
      width: 190,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    },
    { title: '用于授课数', width: 120, key: 'lecture_num' },
    { title: '分发院校数', width: 120, key: 'distribute_school' },
    {
      title: '操作',
      width: 140,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if (ownUrlPermission('material/detail')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', tag: 'a', target: '_blank', href: `/full-page/material-preview?id=${ row.id }` }, { default: () => '查看' }));
            }
            if (ownUrlPermission('material/distribute')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => toDistribute('single', row) }, { default: () => '分发院校' }));
            }
            if (ownUrlPermission('material/update')) {
              tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => { router.push(`build?id=${ row.id }`); } }, { default: () => '教材建设' }));
            }
            if (ownUrlPermission('material/delete')) {
              tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDel('single', row) }, { default: () => '删除' }));
            }
            return tempArr;
          }
        }
      )  
    }
  ];
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  const tableData = ref([]);
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = page => {
    pagination.page = page;
    updateTableData();
  };
  const handlePageSizeChange = pageSize => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const getReqParams = () => {
    const {
      page,
      pageSize: page_size
    } = pagination;
    const params = {
      ...searchFormRef.value.getSearchParams(),
      page,
      page_size
    };
    return params;
  };
  const updateTableData = () => {
    loading.value = true;
    getMaterialList(getReqParams()).then(res => {
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);
        updateIdMaterialMap(res.data.list);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  onMounted(() => {
    updateTableData();
  });
  const delReq = ids => {
    loading.value = true;
    batDelMaterial({
      'Material[ids]': ids
    }).then(res => {
      if (res.code === SUCCESS) {
        message.success(ids.length > 1 ? '批量删除成功' : '删除成功');
        handlePageChange(1);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };
  const toDel = (type, row) => {
    switch (type) {
      case 'single':
        const { id, title } = row;
        dialog.warning({
          title: '删除确认',
          content: `确定删除教材“${title}”？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delReq([id]);
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const ids = selectedIds.value;
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个教材？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            delReq(ids);
          },
          onNegativeClick: () => {}
        });
        break;
    }
  };

  const distributeRef = ref(null);
  const materialsForDistribute = ref([]);
  const idMaterialMap = {};
  const updateIdMaterialMap = list => {
    if (Array.isArray(list)) {
      list.forEach(({ id, title, admin_username }) => {
        idMaterialMap[id] = {
          id,
          title,
          admin_username
        };
      });
    }
  };
  const toDistribute = (type, row) => {
    switch (type) {
      case 'multiple':
        const tempArr = [];
        selectedIds.value.forEach(id => {
          idMaterialMap[id] && tempArr.push(idMaterialMap[id]);
        });
        materialsForDistribute.value = tempArr;
        break;
      case 'single':
        const {
          id,
          title,
          admin_username
        } = row;
        materialsForDistribute.value = [{
          id,
          title,
          admin_username
        }];
        break;
    }
    distributeRef.value.openModal();
  };

  const createModalRef = ref(null);
  const handleClickAdd = () => {
    createModalRef.value.openModal();
  };
</script>